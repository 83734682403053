<template>
  <TTView>
    <VRow>
      <VCol>
        TTAutocomplete
      </VCol>
    </VRow>

    <VRow>
      <VCol cols="12">
        <div class="tt-text-subtitle py-7">
          Full
        </div>
      </VCol>
    </VRow>

    <VRow v-if="autocompletes.full">
      <VCol cols="4">
        <div class="tt-text-body-1 tt-light-mono-64--text my-1">
          56px
        </div>
        <TTAutocomplete
          v-model="autocompletes.full[0].value"
          :items="autocompletes.full[0].items"
          x-large
          placeholder="Placeholder"
          label="Title"
          :messages="['Description']"
        />
      </VCol>
      <VCol cols="4">
        <div class="tt-text-body-1 tt-light-mono-64--text my-1">
          44px
        </div>
        <TTAutocomplete
          v-model="autocompletes.full[1].value"
          :items="autocompletes.full[1].items"
          large
          placeholder="Placeholder"
          label="Title"
          :messages="['Description']"
        />
      </VCol>
    </VRow>

    <VRow v-if="autocompletes.full">
      <VCol cols="4">
        <div class="tt-text-body-1 tt-light-mono-64--text my-1">
          36px
        </div>
        <TTAutocomplete
          v-model="autocompletes.full[2].value"
          :items="autocompletes.full[2].items"
          placeholder="Placeholder"
          label="Title"
          :messages="['Description']"
        />
      </VCol>

      <VCol cols="4">
        <div class="tt-text-body-1 tt-light-mono-64--text my-1">
          24px
        </div>
        <TTAutocomplete
          v-model="autocompletes.full[3].value"
          :items="autocompletes.full[3].items"
          small
          placeholder="Placeholder"
          label="Title"
          :messages="['Description']"
        />
      </VCol>
    </VRow>

    <VRow>
      <VCol cols="12">
        <div class="tt-text-subtitle py-7">
          Without title
        </div>
      </VCol>
    </VRow>

    <VRow v-if="autocompletes.withoutTitle">
      <VCol cols="4">
        <div class="tt-text-body-1 tt-light-mono-64--text my-1">
          56px
        </div>
        <TTAutocomplete
          v-model="autocompletes.withoutTitle[0].value"
          :items="autocompletes.withoutTitle[0].items"
          x-large
          placeholder="Placeholder"
          :messages="['Description']"
        />
      </VCol>
      <VCol cols="4">
        <div class="tt-text-body-1 tt-light-mono-64--text my-1">
          44px
        </div>
        <TTAutocomplete
          v-model="autocompletes.withoutTitle[1].value"
          :items="autocompletes.withoutTitle[1].items"
          large
          placeholder="Placeholder"
          :messages="['Description']"
        />
      </VCol>
    </VRow>

    <VRow v-if="autocompletes.withoutTitle">
      <VCol cols="4">
        <div class="tt-text-body-1 tt-light-mono-64--text my-1">
          36px
        </div>
        <TTAutocomplete
          v-model="autocompletes.withoutTitle[2].value"
          :items="autocompletes.withoutTitle[2].items"
          placeholder="Placeholder"
          :messages="['Description']"
        />
      </VCol>

      <VCol cols="4">
        <div class="tt-text-body-1 tt-light-mono-64--text my-1">
          24px
        </div>
        <TTAutocomplete
          v-model="autocompletes.withoutTitle[3].value"
          :items="autocompletes.withoutTitle[3].items"
          small
          placeholder="Placeholder"
          :messages="['Description']"
        />
      </VCol>
    </VRow>

    <VRow>
      <VCol cols="12">
        <div class="tt-text-subtitle py-7">
          Without Description
        </div>
      </VCol>
    </VRow>

    <VRow v-if="autocompletes.withoutDescription">
      <VCol cols="4">
        <div class="tt-text-body-1 tt-light-mono-64--text my-1">
          56px
        </div>
        <TTAutocomplete
          v-model="autocompletes.withoutDescription[0].value"
          :items="autocompletes.withoutDescription[0].items"
          x-large
          placeholder="Placeholder"
          label="Title"
        />
      </VCol>
      <VCol cols="4">
        <div class="tt-text-body-1 tt-light-mono-64--text my-1">
          44px
        </div>
        <TTAutocomplete
          v-model="autocompletes.withoutDescription[1].value"
          :items="autocompletes.withoutDescription[1].items"
          large
          placeholder="Placeholder"
          label="Title"
        />
      </VCol>
    </VRow>

    <VRow v-if="autocompletes.withoutDescription">
      <VCol cols="4">
        <div class="tt-text-body-1 tt-light-mono-64--text my-1">
          36px
        </div>
        <TTAutocomplete
          v-model="autocompletes.withoutDescription[2].value"
          :items="autocompletes.withoutDescription[2].items"
          placeholder="Placeholder"
          label="Title"
        />
      </VCol>

      <VCol cols="4">
        <div class="tt-text-body-1 tt-light-mono-64--text my-1">
          24px
        </div>
        <TTAutocomplete
          v-model="autocompletes.withoutDescription[3].value"
          :items="autocompletes.withoutDescription[3].items"
          small
          placeholder="Placeholder"
          label="Title"
        />
      </VCol>
    </VRow>

    <VRow>
      <VCol cols="12">
        <div class="tt-text-subtitle py-7">
          Fields only
        </div>
      </VCol>
    </VRow>

    <VRow v-if="autocompletes.fieldsOnly">
      <VCol cols="4">
        <div class="tt-text-body-1 tt-light-mono-64--text my-1">
          56px
        </div>
        <TTAutocomplete
          v-model="autocompletes.fieldsOnly[0].value"
          :items="autocompletes.fieldsOnly[0].items"
          x-large
          placeholder="Placeholder"
        />
      </VCol>
      <VCol cols="4">
        <div class="tt-text-body-1 tt-light-mono-64--text my-1">
          44px
        </div>
        <TTAutocomplete
          v-model="autocompletes.fieldsOnly[1].value"
          :items="autocompletes.fieldsOnly[1].items"
          large
          placeholder="Placeholder"
        />
      </VCol>
    </VRow>

    <VRow v-if="autocompletes.fieldsOnly">
      <VCol cols="4">
        <div class="tt-text-body-1 tt-light-mono-64--text my-1">
          36px
        </div>
        <TTAutocomplete
          v-model="autocompletes.fieldsOnly[2].value"
          :items="autocompletes.fieldsOnly[2].items"
          placeholder="Placeholder"
        />
      </VCol>

      <VCol cols="4">
        <div class="tt-text-body-1 tt-light-mono-64--text my-1">
          24px
        </div>
        <TTAutocomplete
          v-model="autocompletes.fieldsOnly[3].value"
          :items="autocompletes.fieldsOnly[3].items"
          small
          placeholder="Placeholder"
        />
      </VCol>
    </VRow>

    <VRow>
      <VCol cols="12">
        <div class="tt-text-subtitle py-7">
          Multiple
        </div>
      </VCol>
    </VRow>

    <VRow v-if="autocompletes.multiple">
      <VCol cols="4">
        <div class="tt-text-body-1 tt-light-mono-64--text my-1">
          56px
        </div>
        <TTAutocomplete
          v-model="autocompletes.multiple[0].value"
          multiple
          :items="autocompletes.multiple[0].items"
          x-large
          placeholder="Placeholder"
        />
      </VCol>
      <VCol cols="4">
        <div class="tt-text-body-1 tt-light-mono-64--text my-1">
          44px
        </div>
        <TTAutocomplete
          v-model="autocompletes.multiple[1].value"
          multiple
          :items="autocompletes.multiple[1].items"
          large
          placeholder="Placeholder"
        />
      </VCol>
    </VRow>

    <VRow v-if="autocompletes.multiple">
      <VCol cols="4">
        <div class="tt-text-body-1 tt-light-mono-64--text my-1">
          36px
        </div>
        <TTAutocomplete
          v-model="autocompletes.multiple[2].value"
          multiple
          :items="autocompletes.multiple[2].items"
          placeholder="Placeholder"
        />
      </VCol>

      <VCol cols="4">
        <div class="tt-text-body-1 tt-light-mono-64--text my-1">
          24px
        </div>
        <TTAutocomplete
          v-model="autocompletes.multiple[3].value"
          multiple
          :items="autocompletes.multiple[3].items"
          small
          placeholder="Placeholder"
        />
      </VCol>
    </VRow>

    <VRow>
      <VCol cols="12">
        <div class="tt-text-subtitle py-7">
          Chips
        </div>
      </VCol>
    </VRow>

    <VRow v-if="autocompletes.chips">
      <VCol cols="4">
        <div class="tt-text-body-1 tt-light-mono-64--text my-1">
          56px
        </div>
        <TTAutocomplete
          v-model="autocompletes.chips[0].value"
          multiple
          chips
          :items="autocompletes.chips[0].items"
          x-large
          placeholder="Placeholder"
        />
      </VCol>
      <VCol cols="4">
        <div class="tt-text-body-1 tt-light-mono-64--text my-1">
          44px
        </div>
        <TTAutocomplete
          v-model="autocompletes.chips[1].value"
          multiple
          chips
          :items="autocompletes.chips[1].items"
          large
          placeholder="Placeholder"
        />
      </VCol>
    </VRow>

    <VRow v-if="autocompletes.chips">
      <VCol cols="4">
        <div class="tt-text-body-1 tt-light-mono-64--text my-1">
          36px
        </div>
        <TTAutocomplete
          v-model="autocompletes.chips[2].value"
          multiple
          chips
          :items="autocompletes.chips[2].items"
          placeholder="Placeholder"
        />
      </VCol>

      <VCol cols="4">
        <div class="tt-text-body-1 tt-light-mono-64--text my-1">
          24px
        </div>
        <TTAutocomplete
          v-model="autocompletes.chips[3].value"
          multiple
          chips
          :items="autocompletes.chips[3].items"
          small
          placeholder="Placeholder"
        />
      </VCol>
    </VRow>

    <VRow>
      <VCol cols="12">
        <div class="tt-text-subtitle py-7">
          Clearable
        </div>
      </VCol>
    </VRow>

    <VRow v-if="autocompletes.clearable">
      <VCol cols="4">
        <div class="tt-text-body-1 tt-light-mono-64--text my-1">
          56px
        </div>
        <TTAutocomplete
          v-model="autocompletes.clearable[0].value"
          clearable
          :items="autocompletes.clearable[0].items"
          x-large
          placeholder="Placeholder"
        />
      </VCol>
      <VCol cols="4">
        <div class="tt-text-body-1 tt-light-mono-64--text my-1">
          44px
        </div>
        <TTAutocomplete
          v-model="autocompletes.clearable[1].value"
          clearable
          :items="autocompletes.clearable[1].items"
          large
          placeholder="Placeholder"
        />
      </VCol>
    </VRow>

    <VRow v-if="autocompletes.clearable">
      <VCol cols="4">
        <div class="tt-text-body-1 tt-light-mono-64--text my-1">
          36px
        </div>
        <TTAutocomplete
          v-model="autocompletes.clearable[2].value"
          clearable
          :items="autocompletes.clearable[2].items"
          placeholder="Placeholder"
        />
      </VCol>

      <VCol cols="4">
        <div class="tt-text-body-1 tt-light-mono-64--text my-1">
          24px
        </div>
        <TTAutocomplete
          v-model="autocompletes.clearable[3].value"
          clearable
          :items="autocompletes.clearable[3].items"
          small
          placeholder="Placeholder"
        />
      </VCol>
    </VRow>

    <VRow>
      <VCol cols="12">
        <div class="tt-text-subtitle py-7">
          Unactive
        </div>
      </VCol>
    </VRow>

    <VRow v-if="autocompletes.unactive">
      <VCol cols="4">
        <div class="tt-text-body-1 tt-light-mono-64--text my-1">
          56px
        </div>
        <TTAutocomplete
          v-model="autocompletes.unactive[0].value"
          :items="autocompletes.unactive[0].items"
          x-large
          placeholder="Placeholder"
          label="Title"
          :messages="['Description']"
          disabled
        />
      </VCol>
      <VCol cols="4">
        <div class="tt-text-body-1 tt-light-mono-64--text my-1">
          44px
        </div>
        <TTAutocomplete
          v-model="autocompletes.unactive[1].value"
          :items="autocompletes.unactive[1].items"
          large
          placeholder="Placeholder"
          label="Title"
          :messages="['Description']"
          disabled
        />
      </VCol>
    </VRow>

    <VRow v-if="autocompletes.unactive">
      <VCol cols="4">
        <div class="tt-text-body-1 tt-light-mono-64--text my-1">
          36px
        </div>
        <TTAutocomplete
          v-model="autocompletes.unactive[2].value"
          :items="autocompletes.unactive[2].items"
          placeholder="Placeholder"
          label="Title"
          :messages="['Description']"
          disabled
        />
      </VCol>

      <VCol cols="4">
        <div class="tt-text-body-1 tt-light-mono-64--text my-1">
          24px
        </div>
        <TTAutocomplete
          v-model="autocompletes.unactive[3].value"
          :items="autocompletes.unactive[3].items"
          small
          placeholder="Placeholder"
          label="Title"
          :messages="['Description']"
          disabled
        />
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
export default {
  name: 'Autocomplete',
  data() {
    return {
      autocompletes: {
        full: null,
        withoutTitle: null,
        withoutDescription: null,
        fieldsOnly: null,
        multiple: null,
        chips: null,
        clearable: null,
        unactive: null,
      },
    };
  },
  computed: {
    selectedItems() {
      return this.autocompletes[1].value.length;
    },
  },
  mounted() {
    this.generateValues();
  },
  methods: {
    generateValues() {
      const autocompleteTypes = Object.keys(this.autocompletes);
      autocompleteTypes.forEach((type) => {
        this.autocompletes[type] = [];
        for (let i = 0; i < 4; i += 1) {
          this.autocompletes[type].push({
            value: '',
            items: [
              'Lorem long text text long text text long text text', 'Ipsum', 'Dolor', 'Set', 'Amen',
              'Situs', 'Ips2um', 'Dolo2r', 'Set3', 'Amen3', 'Situs2'],
          });
        }
      });
    },
  },
};
</script>
